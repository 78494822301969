<template>
    <div class="container">
        <div
            @click="goback"
            style="font-size: 14px; margin-bottom: 10px; cursor: pointer; width: 50px"
        >
            <i class="el-icon-back"></i>
            返回
        </div>
        <el-form ref="form" :model="form" style="width: 600px" :rules="rules">
            <el-form-item
                prop="modelConfig.schema"
                label="schema"
                label-width="160px"
            >
                <el-select v-model="form.modelConfig.schema">
                    <el-option
                        v-for="item in schemalist"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item prop="client_product_code" label="所属产品" label-width="160px">
          <el-select :disabled="ProCode!==''" v-model="form.client_product_code">
            <el-option
              v-for="item in prolist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item> -->
            <el-form-item prop="model" label="内部型号" label-width="160px">
                <el-input v-model="form.model"></el-input>
            </el-form-item>
            <el-form-item prop="name" label="外部型号" label-width="160px">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="默认名称" label-width="160px">
                <el-input v-model="form.modelConfig.name"></el-input>
            </el-form-item>
            <el-form-item label="是否输入型号密钥" label-width="160px">
                <el-radio-group v-model="secretType">
                    <el-radio :label="1">手动填写</el-radio>
                    <el-radio :label="2">自动生成</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                v-if="secretType == 1"
                prop="secret"
                label="产品型号秘钥"
                label-width="160px"
            >
                <el-input v-model="form.secret"></el-input>
            </el-form-item>
            <el-form-item
                prop="category_code"
                label="所属品类"
                label-width="160px"
            >
                <el-select v-model="form.category_code">
                    <el-option
                        v-for="item in categorylist"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                prop="client_brand_code"
                label="所属品牌"
                label-width="160px"
            >
                <el-select v-model="form.client_brand_code">
                    <el-option
                        v-for="item in brandlist"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                prop="set_network_codes"
                label="配网方式集合"
                label-width="160px"
            >
                <el-select v-model="form.set_network_codes" multiple>
                    <el-option
                        v-for="item in netlist"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                prop="category_panel_code"
                label="面板"
                label-width="160px"
            >
                <el-select v-model="form.category_panel_code">
                    <el-option
                        v-for="item in panellist"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- 型号配置 -->
            <el-form-item
                label="产品类型"
                label-width="160px"
                prop="modelConfig.model_type"
            >
                <el-radio-group v-model="form.modelConfig.model_type">
                    <el-radio :label="1">普通设备</el-radio>
                    <el-radio :label="2">低功耗设备</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop="modelConfig.number"
                label="涂鸦产品唯一编号"
                label-width="160px"
            >
                <el-input v-model="form.modelConfig.number"></el-input>
            </el-form-item>
            <el-form-item label="产品状态" label-width="160px">
                <el-radio-group v-model="form.modelConfig.status">
                    <el-radio :label="0">测试</el-radio>
                    <el-radio :label="1">上架</el-radio>
                    <el-radio :label="2">下架</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop="modelConfig.config"
                label="产品默认json配置"
                label-width="160px"
            >
                <!-- <vue-json-editor :show-btns="false" :mode="'code'" id="editor_holder" :expandedOnStart="true" v-model="jsonConfig" @json-change="onJsonChange"></vue-json-editor> -->
                <el-input
                    :rows="5"
                    type="textarea"
                    v-model="form.modelConfig.config"
                ></el-input>
            </el-form-item>
            <el-form-item
                prop="modelConfig.level"
                label="产品级别"
                label-width="160px"
            >
                <el-radio-group v-model="form.modelConfig.level">
                    <el-radio :label="1">一级设备</el-radio>
                    <el-radio :label="2">二级设备</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="产品低电量提醒界限值" label-width="160px">
                <!-- <el-input v-model="form.modelConfig.low_battery"></el-input> -->
                <el-input-number
                    v-model="form.modelConfig.low_battery"
                    :min="0"
                ></el-input-number>
            </el-form-item>
            <el-form-item
                prop="modelConfig.category"
                label="alexa所属类别"
                label-width="160px"
            >
                <el-select v-model="form.modelConfig.category">
                    <el-option
                        v-for="item in alexList"
                        :key="item.Code"
                        :label="item.Name"
                        :value="item.Code"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                prop="modelConfig.alexa_status"
                label="支持alexa"
                label-width="160px"
            >
                <el-radio-group v-model="form.modelConfig.alexa_status">
                    <el-radio :label="1">支持</el-radio>
                    <el-radio :label="2">不支持</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop="modelConfig.google_status"
                label="支持googlehome"
                label-width="160px"
            >
                <el-radio-group v-model="form.modelConfig.google_status">
                    <el-radio :label="1">支持</el-radio>
                    <el-radio :label="2">不支持</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop="modelConfig.home_kit_status"
                label="支持home kit"
                label-width="160px"
            >
                <el-radio-group v-model="form.modelConfig.home_kit_status">
                    <el-radio :label="1">支持</el-radio>
                    <el-radio :label="2">不支持</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop="modelConfig.smart_thing_status"
                label="支持smart_thing"
                label-width="160px"
            >
                <el-radio-group v-model="form.modelConfig.smart_thing_status">
                    <el-radio :label="1">支持</el-radio>
                    <el-radio :label="2">不支持</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="关联套餐" label-width="160px">
                <el-button type="info" @click="relatePack"
                    >编辑关联套餐</el-button
                >
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="Validate(onSubmit)">{{
                    submitbutton
                }}</el-button>
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>
        <!-- 已有套餐 -->
        <h4>已关联套餐</h4>
        <el-table border v-bind:data="modelpackList">
            <el-table-column label="套餐名称">
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐时长">
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.time +
                            '(' +
                            scope.row.SetMeal.time_unit +
                            ')'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐价格">
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.price }}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐售价">
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.saleprice }}</span>
                </template>
            </el-table-column>
            <el-table-column label="币种">
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.currency }}</span>
                </template>
            </el-table-column>
            <el-table-column label="服务等级">
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.level === 1
                            ? 'Starter'
                            : scope.row.SetMeal.level === 2
                            ? 'Standard'
                            : scope.row.SetMeal.level === 3
                            ? 'Pro'
                            : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐状态">
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.status == 0
                            ? '测试'
                            : scope.row.SetMeal.status == 1
                            ? '上架'
                            : scope.row.SetMeal.status == 2
                            ? '下架'
                            : scope.row.SetMeal.status == 3
                            ? '已删除'
                            : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="周期">
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.cycles }}</span>
                </template>
            </el-table-column>
            <el-table-column label="是否针对事件进行存储 ">
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.is_event == 0
                            ? '否'
                            : scope.row.SetMeal.is_event == 1
                            ? '是'
                            : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="套餐到期时间">
        <template v-slot="scope">
          <span>{{ changeTime(scope.row.SetMeal.expire) }}</span>
        </template>
      </el-table-column> -->
        </el-table>
        <el-pagination
            @current-change="handlemodelpackChange"
            v-model:currentPage="modelpackSearch.page"
            :page-size="modelpackSearch.limit"
            layout="total, prev, pager, next"
            :total="modelpacktotal"
        >
        </el-pagination>

        <el-dialog
            title="型号套餐关联"
            v-model="dialogFormVisible"
            width="1200px"
        >
            <el-form
                ref="form"
                style="flex:1"
                :inline="true"
                :model="packSearch"
                class="demo-form-inline"
                size="mini"
            >
                <el-form-item>
                    <el-input
                        v-model.trim="packSearch.kname"
                        placeholder="模糊搜索名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="packSearch.status"
                        multiple
                        placeholder="状态集合"
                    >
                        <el-option label="测试" :value="0"></el-option>
                        <el-option label="上架" :value="1"></el-option>
                        <el-option label="下架" :value="2"></el-option>
                        <el-option label="已删除" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="packSearch.is_event"
                        clearable
                        placeholder="是否为事件"
                    >
                        <el-option label="否" :value="0"></el-option>
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="全部" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="packSearch.currency"
                        clearable
                        placeholder="币种"
                    >
                        <el-option
                            v-for="item in currencylist"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="Searchpack"
                        >查询</el-button
                    >
                </el-form-item>
            </el-form>
            <el-table
                border
                v-bind:data="packList"
                @sort-change="Sortchange"
                size="mini"
            >
                <el-table-column label="套餐名称" sortable width="260">
                    <template v-slot="scope">
                        <span>{{ scope.row.SetMeal.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐时长" sortable>
                    <template v-slot="scope">
                        <span>{{
                            scope.row.SetMeal.time +
                                '(' +
                                scope.row.SetMeal.time_unit +
                                ')'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐价格" sortable>
                    <template v-slot="scope">
                        <span>{{ scope.row.SetMeal.price }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐售价" width="80">
                    <template v-slot="scope">
                        <span>{{ scope.row.SetMeal.saleprice }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="币种" sortable width="70">
                    <template v-slot="scope">
                        <span>{{ scope.row.SetMeal.currency }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐状态" width="70">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.SetMeal.status == 0
                                ? '测试'
                                : scope.row.SetMeal.status == 1
                                ? '上架'
                                : scope.row.SetMeal.status == 2
                                ? '下架'
                                : scope.row.SetMeal.status == 3
                                ? '已删除'
                                : '未知'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="服务等级" width="60">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.SetMeal.level === 1
                                ? 'Starter'
                                : scope.row.SetMeal.level === 2
                                ? 'Standard'
                                : scope.row.SetMeal.level === 3
                                ? 'Pro'
                                : ''
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="文件时长(天)" sortable width="120">
                    <template v-slot="scope">
                        <span>{{ scope.row.SetMeal.file_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否针对事件进行存储 ">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.SetMeal.is_event == 0
                                ? '否'
                                : scope.row.SetMeal.is_event == 1
                                ? '是'
                                : '未知'
                        }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="套餐到期时间">
          <template v-slot="scope">
            <span>{{ changeTime(scope.row.SetMeal.expire) }}</span>
          </template>
        </el-table-column> -->
                <el-table-column label="操作" width="70">
                    <template v-slot="scope">
                        <input
                            type="checkbox"
                            :checked="scope.row.SetMealProductModel.id !== 0"
                            :ref="'check' + scope.row.SetMeal.code"
                            @change="checkchange(scope.row)"
                        />
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 25px">
                <el-pagination
                    @current-change="handleCurrentChange"
                    v-model:currentPage="packSearch.page"
                    :page-size="packSearch.limit"
                    layout="total, prev, pager, next"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import api from "../../../axios/pack";
import vueJsonEditor from "vue-json-editor";
import util from "../../../util/util";
export default {
  name: "",
  mixins: [],
  components: {
    vueJsonEditor,
  },
  props: {},
  data() {
    return {
      secretType: 2,
      form: {
        code: "",
        client_product_code: "",
        model: "",
        name: "",
        secret: "",
        category_code: "",
        client_brand_code: "",
        set_network_codes: [],
        category_panel_code: "",
        modelConfig: {
          model_type: "",
          number: "",
          status: 0,
          config: "{}",
          level: "",
          low_battery: 1,
          category: "",
          alexa_status: 2,
          google_status: "",
          home_kit_status: "",
          smart_thing_status: "",
          schema: "",
          name: "",
        },
      },
      modelCode: "",
      ProCode: "",
      brandCode: "",
      submitbutton: "",
      currencylist: [],
      rules: {
        code: [{ required: true, message: "请输入记录编号", trigger: "blur" }],
        // client_product_code: [{ required: false, message: '请输入产品编号', trigger: 'blur' }],
        model: [{ required: true, message: "请输入产品型号", trigger: "blur" }],
        name: [
          { required: true, message: "请输入产品型号名称", trigger: "blur" },
        ],
        // secret: [{ required: true, message: '请输入产品型号秘钥', trigger: 'blur' }],
        "modelConfig.status": [
          { required: true, message: "请输入产品状态", trigger: "blur" },
        ],
        "modelConfig.schema": [
          { required: true, message: "请选择schema", trigger: "blur" },
        ],
        "modelConfig.model_type": [
          { required: true, message: "请输入产品类型", trigger: "blur" },
        ],
        // 'modelConfig.number': [{ required: true, message: '请输入产品唯一编号', trigger: 'blur' }],
        "modelConfig.config": [
          {
            required: true,
            message: "请输入产品默认json配置",
            trigger: "blur",
          },
        ],
        "modelConfig.level": [
          { required: true, message: "请输入产品级别", trigger: "blur" },
        ],
        // 'modelConfig.category': [{ required: true, message: '请输入alexa所属类别', trigger: 'blur' }],
        "modelConfig.alexa_status": [
          { required: true, message: "请输入支持alexa", trigger: "blur" },
        ],
        "modelConfig.google_status": [
          { required: true, message: "请输入支持googlehome", trigger: "blur" },
        ],
      },
      //
      prolist: [],
      jsonConfig: "",
      packSearch: {
        page: 1,
        limit: 10,
        kname: "",
        code: "",
        product_model_code: "",
        status: [0,1],
        is_event: -1,
        currency: '',
        order_field: "",
        order_action: 1,
      },
      packList: [],
      total: 0,
      dialogFormVisible: false,
      // 已分配套餐
      modelpackList: [],
      modelpacktotal: 0,
      modelpackSearch: {
        page: 1,
        limit: 10,
        kname: "",
        code: "",
        product_model_code: "",
      },
      schemalist: [],
      netlist: [],
      brandlist: [],
      panellist: [],
      categorylist: [],
      alexList: [],
    };
  },
  computed: {},
  watch: {
    "form.modelConfig.category": function (val) {
      if (val == "") {
        this.form.modelConfig.alexa_status = 2;
      }else{
        this.form.modelConfig.alexa_status = 1;
      }
    },
  },
  methods: {
    GetCurrency () {
      this.api.CurrencyList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.currencylist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetAlexList() {
      this.api.AlexaCategoryList({}).then((res) => {
        if (res.data.code == 200) {
          this.alexList = res.data.data;
          this.GetCategory();
          return;
        }
        this.$message.error("获取失败" + res.data.msg);
      });
    },
    GetCategory() {
      this.api
        .CategoryList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.categorylist = res.data.data.data;
            this.GetPanel();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetPanel() {
      this.api
        .PanelList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.panellist = res.data.data.data;
            this.GetBrand();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetBrand() {
      this.api
        .BrandList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.brandlist = res.data.data.data;
            this.GetNet();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetNet() {
      this.api
        .SetNetworkList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.netlist = res.data.data.data;
            this.GetSchema();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    Sortchange(column) {
      switch (column.column.label) {
        case "套餐名称":
          this.packSearch.order_field = "name";
          break;
        case "套餐时长":
          this.packSearch.order_field = "time";
          break;
        case "套餐价格":
          this.packSearch.order_field = "price";
          break;
        case "币种":
          this.packSearch.order_field = "currency";
          break;
        case "文件时长(天)":
          this.packSearch.order_field = "file_time";
          break;
      }
      this.packSearch.order_action = this.packSearch.order_action == 1 ? 2 : 1;
      this.Searchpack();
    },
    GetSchema() {
      this.api
        .ClientList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.schemalist = res.data.data.data;
            this.GetProList();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    // 已分配列表
    GetmodelpackList(item) {
      api
        .PackageList({
          page: this.modelpackSearch.page,
          limit: this.modelpackSearch.limit,
          kname: this.modelpackSearch.kname,
          code: this.modelpackSearch.code,
          is_event: -1,
          product_model_code: this.modelpackSearch.product_model_code,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.modelpackList = res.data.data.data;
            this.modelpacktotal = res.data.data.page_info.total;
            if (item == "distribute") {
              this.Searchpack();
            }
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    handlemodelpackChange(val) {
      this.modelpackSearch.page = val;
      this.GetmodelpackList();
    },
    relatePack() {
      this.dialogFormVisible = true;
      this.Searchpack();
    },
    handleCurrentChange(val) {
      this.packSearch.page = val;
      this.Searchpack();
    },
    Searchpack(item) {
      api
        .PackageList({
          page: this.packSearch.page,
          limit: this.packSearch.limit,
          kname: this.packSearch.kname,
          code: this.packSearch.code,
          product_model_code: this.modelCode,
          model_action: 1,
          status: JSON.stringify(this.packSearch.status),
          is_event: this.packSearch.is_event,
          currency: this.packSearch.currency,
          order_field: this.packSearch.order_field,
          order_action: this.packSearch.order_action,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.packList = res.data.data.data;
            this.total = res.data.data.page_info.total;
            if (item == "delete") {
              this.GetmodelpackList();
            }
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    // 分配
    checkchange(item) {
      if (item.SetMealProductModel.id == 0) {
        api
          .ProPackDistriUpdate({
            product_model_code: this.modelCode,
            set_meal_code: item.SetMeal.code,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success("分配成功");
              this.GetmodelpackList("distribute");
              return;
            }
            this.$message.error("分配失败" + res.data.msg);
          });
      } else {
        api
          .ProPackDistriDelete({
            id: item.SetMealProductModel.id,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success("取消分配成功");
              this.Searchpack("delete");
              return;
            }
            this.$message.error("取消分配失败" + res.data.msg);
          });
      }
    },
    onJsonChange(value) {
      // 数据改变时触发
      this.jsonConfig = JSON.stringify(value);
      this.form.modelConfig.config = this.jsonConfig;
    },
    GetProList() {
      this.api
        .ProductList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.prolist = res.data.data.data;
            this.GetmodelpackList();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetConfig() {
      this.api
        .ProductModelDetail({
          code: this.modelCode,
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("config", JSON.parse(res.data.data.config.config));
            this.form.modelConfig = res.data.data.config;
            this.jsonConfig = res.data.data.config.config;
            this.form.code = res.data.data.model.code;
            this.form.client_product_code =
              res.data.data.model.client_product_code;
            this.form.model = res.data.data.model.model;
            this.form.name = res.data.data.model.name;
            this.form.secret = res.data.data.model.secret;
            this.form.category_code = res.data.data.model.category_code;
            this.form.client_brand_code = res.data.data.model.client_brand_code;
            this.form.set_network_codes = JSON.parse(
              res.data.data.model.set_network_codes
            )
              ? JSON.parse(res.data.data.model.set_network_codes)
              : this.form.set_network_codes;
            this.form.category_panel_code =
              res.data.data.model.category_panel_code;
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    goback() {
      if (this.$route.params.code.split("&")[2] == "model") {
        this.$router.push("/iot/model");
      } else if (this.$route.params.code.split("&")[2] == "online") {
        this.$router.push("/iot/online");
      } else {
        this.$router.push("/iot/promodel/model/" + this.ProCode);
      }
    },
    Validate: function (call) {
      this.$refs["form"].validate((res) => {
        if (res && call) {
          call();
        }
      });
    },
    onSubmit() {
      this.api
        .ProductModelUpdate({
          code: this.form.code,
          client_product_code: this.form.client_product_code,
          model: this.form.model,
          name: this.form.name,
          secret: this.form.secret,
          category_code: this.form.category_code,
          client_brand_code: this.form.client_brand_code,
          set_network_codes: this.form.set_network_codes,
          category_panel_code: this.form.category_panel_code,
          client_product_model_config_v1: {
            model_type: +this.form.modelConfig.model_type,
            number: this.form.modelConfig.number,
            schema: this.form.modelConfig.schema,
            status: +this.form.modelConfig.status,
            name: this.form.modelConfig.name,
            config: this.form.modelConfig.config,
            level: +this.form.modelConfig.level,
            low_battery: +this.form.modelConfig.low_battery,
            category: this.form.modelConfig.category,
            alexa_status: +this.form.modelConfig.alexa_status,
            google_status: +this.form.modelConfig.google_status,
            home_kit_status: +this.form.modelConfig.home_kit_status,
            smart_thing_status: +this.form.modelConfig.smart_thing_status,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.form = {
              code: "",
              client_product_code: "",
              model: "",
              name: "",
              secret: "",
              modelConfig: {
                model_type: "",
                number: "",
                status: "",
                config: "",
                level: "",
                low_battery: 1,
                category: "",
                alexa_status: "",
                google_status: "",
                home_kit_status: "",
                smart_thing_status: "",
              },
            };
            this.$confirm(this.submitbutton + "成功,是否返回列表页?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.goback();
            });
            return;
          }
          this.$message.error(this.submitbutton + "失败" + res.data.msg);
        });
    },
    changeTime(item) {
      return util.dateFormat(item);
    },
  },
  filters: {},
  mounted() {
    this.modelCode = this.$route.params.code.split("&")[0];
    this.modelpackSearch.product_model_code = this.modelCode;
    this.brandCode = this.$route.params.code.split("&")[1];
    console.log(this.$route.params, 99999);
    console.log(this.brandCode, 99999);

    if (this.brandCode) {
      this.form.client_brand_code = this.brandCode;
    }
    if (this.modelCode !== "0") {
      this.submitbutton = "修改";
      this.GetConfig();
    } else {
      this.submitbutton = "创建";
    }
  },
  created() {
    this.GetAlexList()
    this.GetCurrency()
  },
};
</script>

<style></style>
